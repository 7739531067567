@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Open+Sans+Condensed:300,700&display=swap&subset=cyrillic-ext');

body {
	background-color: #fffff;
	color: #333333;
	font-family: 'Open Sans', sans-serif;
}

// .f12-logo {
// 	width: 300px;
// 	height: 300px;
// 	fill: #ffffff;
// }

h3 {
	font-weight: 400;
}

// Main Nav

.main-menu-container {
	background-color: #211f20;
	position: fixed;
	z-index: 100;
}

.menu, .main-phone {
	height: 51px;
}

.menu, .main-menu {
	.nav-link {
		// font-family: 'Open Sans', sans-serif;
		font-size: 0.875rem;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		&:hover {
			color: #d4b891;
		}
	}
}

.main-phone {
	.nav-link {
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: 0.1em;
		&:hover {
			color: #d4b891;
		}
	}
}

.lang-switch {
	.nav-link {
		font-size: 0.875rem;
	}
}

.modal-title {
	color: #000000;
}

.modal-content {
	border-radius: 0;
}

// mobile --navigation

// #navigation {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	z-index: 1000;
// 	background-color: black;
// 	display: none;
// }

// Slider

.main-slider {
	height: 80vh;
	overflow: hidden;
}

.carousel-item {
	background: no-repeat center center scroll;
	background-size: cover;
	height: 80vh;
}

.carousel-indicators li {
	width: 6px;
	height: 6px;
	border: 1px solid transparent;
	border-radius: 4px;
}

// Heading

h1, h2, h3 {
	font-weight: 300;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

// Logo

.logo {
	width: 110px;
}

// navbar

.bg-light {
	background-color: transparent;
}

.nav-link {
	font-size: 1rem;
}

// Hello

.hello-block {
	height: 41vw;
	background: url('/images/bg03.jpg') 50% 50% no-repeat;
	background-size: cover;

	.navbar {
		margin-left: 20px;
		.nav-link {
			border-top: solid 1px transparent;
			&:hover {
				border-top: solid 1px #D1851E;
				box-sizing: border-box;
			}
		}
		// .nav-link:hover {
		// 	border-top: solid 1px #D1851E;
		// 	box-sizing: border-box;
		// }
	}

	.message {
		color: #ffffff;
		font-size: 1.5rem;
		height: 30vw;
	}

	.contact-block {
		i {
			margin-right: 5px;
			color: #D1851E;
		}
		a {
			font-size: 1rem;
		}
		// margin-bottom: 1rem;
	}
}

.head-block {
	height: 135px;
}

.device-block {
	// height: 41vw;
	background: url('/images/bg04.jpg') 50% 50% no-repeat;
	background-size: cover;
	.message {
		color: #ffffff;
		font-size: 0.875rem;

	}
}

.rodex {
	height: 300px;
}

.tilte-block {
	color: #333;
	background-color: #D1851E;
	border-left: solid 10px #C84621;
	h2 {
		font-weight: bold;
		font-size: 1.5rem;
		margin-bottom: 0;
	}
}


// Default

.default-block {
	padding-top: 50px;
	padding-bottom: 50px;
	h1 {
		line-height: 1.1;
	}
	a {
		color: #000000;
	}
}

.with-title {
	padding-top: 0px;
}

.black-block {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #333;
	color: #fff;
}

.orange-text {
	h3 {
	color: #D1851E;
	font-size: 1.5rem;
	font-weight: bold;
	text-transform: uppercase;
	}
}

.red-block {
	padding-top: 70px;
	padding-bottom: 50px;
	background: url('/images/bg03.jpg') 50% 50% no-repeat;
	background-size: cover;
	color: #ffffff;
	font-size: 1.2rem;
	h3 {
		font-weight: bold;
	}
	.message {
		margin-top: 50px;
		h3 {
			text-transform: uppercase;
			margin-bottom: 50px;
		}
	}
}

.border-block {
	border: solid 1px #ffffff;
	min-height: 300px;
}

.orange {
	background-color: #D1851E;
}

.feature {
	height: 300px;
	p {
		margin-top: 1em;
	}
}

.vavilon-icon {
	height: 115px;
}

// Request

.request-block {
	background-color: #C84621;
	color: #fff;
	font-size: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	p {
		margin-bottom: 0;
	}
}

.anchor {
	position: absolute;
	margin-top: -43px;
}

// Footer

.footer-block {
	background-color: #333333;
	color: #ffffff;
	// border-top: solid 2px #d4b891;
	padding-top: 1rem;
	padding-bottom: 1rem;
}


#sticky-nav {
	position: fixed;
	margin: 0 auto;
	width: 100%;
	// height: 100px;
	background-color: #211f20;
	z-index: 2000;
}

.menu-logo {
	width: 120px;
}

.social-icon {
	width: 30px;
}

.payment i, .social i {
	margin-right: 0.5em;
	// color: #d4b891;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.hello-block {
		height: 80vh;
		.message {
			height: 70vw;
		}
	}

	.feature {
		height: 200px;
	}

	.rodex {
		height: 450px;
	}

	.border-block {
		margin-bottom: 30px;
	}

}
